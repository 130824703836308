import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { GatsbyImage } from 'gatsby-plugin-image'

const IndexPage = ({ data }) => {
    const { strapiContactUs: pageData } = data
    const leftMd = pageData.fields?.leftMarkdownBody?.childMarkdownRemark.html
    const rightMd = pageData.fields?.rightMarkdownBody?.childMarkdownRemark.html
    const backgroundImage =
        pageData.BackgroundImage?.localFile?.childImageSharp.gatsbyImageData
    return (
        <Layout backgroundImage={backgroundImage}>
            <Seo title='Home' />
            <div className='df grid md50 contact-us-page'>
                <div>
                    <h2>{pageData.Left.Title}</h2>
                    <GatsbyImage
                        image={
                            pageData.Left.Image?.localFile?.childImageSharp
                                .gatsbyImageData
                        }
                    />
                    <div
                        className='md'
                        dangerouslySetInnerHTML={{ __html: leftMd }}
                    />
                </div>
                <div>
                    <h2>{pageData.Right.Title}</h2>
                    <GatsbyImage
                        image={
                            pageData.Right.Image?.localFile?.childImageSharp
                                .gatsbyImageData
                        }
                    />
                    <div
                        className='md'
                        dangerouslySetInnerHTML={{ __html: rightMd }}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage
export const pageQuery = graphql`
    query ContactUs {
        strapiContactUs {
            Left {
                Title
                Image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, width: 400)
                        }
                    }
                }
            }
            Right {
                Title
                Image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, width: 400)
                        }
                    }
                }
            }
            BackgroundImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            fields {
                leftMarkdownBody {
                    childMarkdownRemark {
                        html
                    }
                }
                rightMarkdownBody {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
`
